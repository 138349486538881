const Up = Symbol("Up");
const Down = Symbol("Down");
const Left = Symbol("Left");
const Right = Symbol("Right");
const Jump = Symbol("Jump");
const Interact = Symbol("Interact");
const Escape = Symbol("Escape");
const Map = Symbol("Map");

// TODO Convert this to an enum
export const Input = {
  Down,
  Escape,
  Interact,
  Jump,
  Left,
  Right,
  Up,
  Map,
};
