export const CANVAS_WIDTH = 1280;
export const CANVAS_HEIGHT = 720;

// export const HORIZONTAL_TILES = 32;
// export const VERTICAL_TILES = 18;

// export const PIXELS_PER_TILE = 10;

// export const PIXEL_WIDTH = CANVAS_WIDTH / HORIZONTAL_TILES / PIXELS_PER_TILE;

export const IS_MOBILE =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

export const ON_SCREEN_CANVAS_WIDTH = 1280;
export const ON_SCREEN_CANVAS_HEIGHT = 720;
